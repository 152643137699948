@media only screen and (min-width: 1440px) {
  .popup-content {
    top: 400px;
  }
}
@media only screen and (max-width: 1360px) {
  .auth-form {
    width: 100%;
  }
  .popup-content {
    top: 400px;
  }
}
@media only screen and (max-width: 1280px) {
  ul.suite-boxes {
    margin-top: -100px;
    width:90%;
  }
  .popup-content {
    top: 390px;
  }
}
@media only screen and (max-width: 1200px) {
  ul.suite-boxes {
    /* padding: 40px 0; */
    width:80%;
  }
}
@media only screen and (max-width: 1024px) and (max-height: 800px) {
  ul.suite-boxes {
    width:70%;
    margin-top: 15px;
  }
  ul.suite-boxes li {
    width: 120px;
    height: 140px;
  }
  .popup-content {
    top: 350px;
    }
  .header {
    padding-left: 0px;
    z-index: 9;
  }
  .logoLogin img {
    width: 80px;
  }
  .loginFormRight{
    margin:30px 0;
  }
  .login-right-box{
    margin:0 auto;
  }
  .screens {
    width: 100%;
  }
  .page-content {
    margin-top: 68px;
    height: 100%;
  }
  .mobile-logo {
    display: block;
  }
  .all-screens-head span {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1024px) and (max-height: 1400px) and (min-height: 800px) {
  ul.suite-boxes {
    width: 60%;
  }
  ul.suite-boxes li {
    width: 160px;
    height: 178px;
  }
  .popup-content {
    top: 600px;
    width: 607px;
    height: 424px;
    }
  .popup-header {
    width: 607px;
    height: 120px;
  }
  .popup-header .zingit-logo {
    width: 35.87px;
    height: 35px;
    left: 260px;
    top: 15px;
  }
  .popup-header .suite {
    width: 46px;
    height: 24px;
    left: 303px;
    top: 21px; 
    font-weight: 550;
    font-size: 22px;
    line-height: 26px;
  }
  .popup-header .signed {
    top: 65px;
    left: 216px;
    font-size: 20px;
    line-height: 19px;
  }
  .popup-body {
    width: 86%;
    height:65%;
    top: 130px;
    left: 67px;
    background: #fff;
  }
  .popup-body.export {
    top: 80px;
  }
  .popup-body .para-1 p:first-child {
    top: 15px;
    font-size: 20px;
    line-height: 22px;
  }
  .popup-body .para-1 p:last-child {
    top: 42px;
    font-size: 16px;
    line-height: 16px;
  }
  .btn-continue {
    width: 480px;
    height: 60px;
    left: 0px;
    top: 80px;
  }
  .para-2 p:first-child {
    top: 154px;
    left: 225px;
    font-size: 20px;
 }
  .para-2 a{
    top: 182px;
    left: 40px;
    font-weight: 500;
    font-size: 22px;
    line-height: 170%;
  }
  .para-3 a{
    top: 160px;
    left: 200px;
  }
  .close-popup {
    position: absolute;
    top: 13px;
    right: 10px;
    padding: 5px 7px;
    color: #4D4D4D;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .popup-header .signed2{
    left:267px;
 }
}

@media only screen and (max-width: 830px) {
  ul.suite-boxes {
    margin-top: 10%;
    margin-left: 25%;
    width: 70%;
  }
  ul.suite-boxes li {
    width: 140px;
    height: 162px;
  }
  .popup-content {
    top: 400px;
    }
  .para-2 a{
    right: 0px;
  }
  .para-3 a{
    top: 160px;
    left: 200px;
  }
  .popup-header .signed2{
    left:267px;
 }
  .loginFormRight {
    width: 100%;
    margin:40px auto;
  }
  .login-right-box{
    width:462px;
    padding: 20px;
    margin:0px auto;
  }
  .header {
    padding-left: 0px;
    position: fixed;
    z-index: 9;
    top:0;
  }
  .page-content {
    margin-top: 68px;
    height: 100%;
  }
  .loginPage {
    background: #ffffff;
  }

  .auth-form form label {
    font-size: 15px;
  }
  .auth-screen {
    display: block;
  }
  .logoLogin span {
    font-size: 22px;
  }
  .auth-form {
    width: 100%;
    margin-right: 0 !important;
    padding: 10px;
  }
  .auth-form {
    padding: 0 !important;
    margin-top: 30px;
  }
  .float-left.search-mob.position-relative {
    margin-right: 10px;
    width: 100%;
  }
  .__react_component_tooltip {
    display: none !important;
  }
  .page-header-button {
    min-width: 145px;
  }
  .all-screens-head span {
    padding-left: 10px;
  }
  .screens {
    width: 100%;
  }
  .page-header-button {
    min-width: auto;
    font-size: 15px;
    padding: 3px 7px;
    line-height: 30px;
  }
  .set-notification {
    font-size: 13px;
  }
}

@media only screen and (max-width: 830px) and (max-height:800px ) {
  .para-3 a{
    top: 150px;
    left: 135px;
  }
}

@media only screen and (max-width: 767px) {
  ul.suite-boxes {
    margin-top: 40px;
    width: 90%;
    /* justify-content: center; */
  }
  ul.suite-boxes li {
    width: 110px;
    height: 128px;
  }
  .header {
    padding-left: 0px;
    position: fixed;
    z-index: 9;
    top:0;
  }
  .auth-form button,
  .auth-form a.button {
    font-size: 15px;
  }
  .auth-form a.button.btn.btn-primary {
    background: #0079c1;
    border: 1px solid #0079c1;
  }
  a.after-login {
    width: 100% !important;
  }
  .page-content {
    margin-top: 68px;
    height: 100%;
  }
  .loginPage {
    background: #ffffff;
  }
  .loginFormRight {
    width: 100%;
    margin:40px auto;
  }
  .login-right-box{
    width:462px;
    padding: 20px;
    margin:0px auto;
  }
  .new-head-right a{
    font-size: 13px;
  }
  .new-head-right img {
    margin-right: 7px;
  }
  .auth-form form label {
    font-size: 15px;
  }
  .auth-screen {
    display: block;
  }
  .logoLogin span {
    font-size: 22px;
  }
  .auth-form {
    width: 100%;
    margin-right: 0 !important;
    padding: 10px;
  }
  .auth-form {
    padding: 0 !important;
    margin-top: 30px;
  }
  .float-left.search-mob.position-relative {
    margin-right: 10px;
    width: 100%;
  }
  .__react_component_tooltip {
    display: none !important;
  }
  .page-header-button {
    min-width: 145px;
  }
  .all-screens-head span {
    padding-left: 10px;
    font-size: 14px;
  }
  .beta-text{
    right:-29px;
    font-size: 9px;
  }
  .aside.mini + .screens {
    width: 100%;
  }
  .screens {
    width: 100%;
  }
  .page-header-button {
    min-width: auto;
    font-size: 15px;
    padding: 3px 7px;
    line-height: 30px;
  }
  .header .navbar{
    padding: 0 10px;
  }
  img.accountLogo{
    display: none;
  }
  .contact-support-for-mobile {
    margin-top: 20px;
    display: block;
  }
  .contact-support-broadcaste .support-info {
    width: 94%;
    padding: 20px;
  }
  .contact-support-broadcaste {
    width: 100%;
    height: calc(100vh - 66px);
    float: left;
    margin-top: 68px;
  }
}
@media only screen and (max-width: 542px) {
  ul.suite-boxes {
    margin-top: 40px;
    width: 60%;
  }
  ul.suite-boxes li {
    width: 100px;
    height: 116px;
  }
  .popup-content {
    top: 50%;
    }
  .para-2 a {
    padding-right: 128px;
  }
  .para-3 a{
    top: 140px;
    left: 135px;
  }
  .popup-header .signed2{
    left:171px;
 }
  img.accountLogo {
    width: 7px;
  }
  .loginFormRight {
    width: 100%;
    margin:90px auto;
  }
  .login-right-box{
    width:95%;
    padding: 20px;
    margin:0px auto;
  }
  .defalut-loader-screen{
    padding: 0 15px;
  }
}

@media only screen and (max-width: 480px) {
  ul.suite-boxes {
    margin-top: 80px;
    margin-left: 15%;
    width: 80%;
  }
  ul.suite-boxes li {
    width: 100px;
    height: 116px;
  }
  .popup-content {
    width: 380px;
    height: 265px;
  }
  .popup-header {
    width: 380px;
    height: 74px;
  }
  .popup-header .zingit-logo {
    width: 25px;
    height: 25px;
    left: 165px;
    top: 19px;
  }
  .popup-header .suite {
    font-weight: 450;
    font-size: 17px;
    line-height: 23px;
    top: 20px; 
    left: 195px;
  }
  .popup-header .signed {
    font-size: 16px;
    line-height: 18px;
    top: 48px;
    left: 130px;
  }
  .popup-body {
    top: 75px;
    left: 25px;
    background:#fff;
  }
  .popup-body .para-1 {
    top: 8px;
    left: 7px;
    font-size: 16px;
    line-height: 19px;
  }
  .popup-body .para-1 p:first-child {
    top: 15px;
    font-size: 19px;
    line-height: 20px;
  }
  .popup-body .para-1 p:last-child {
    top: 40px;
    font-size: 15px;
    line-height: 16px;
  }
  .btn-continue {
    width: 320px;
    height: 40px;
    left: 4px;
    top: 72px;
    padding-top: 5px;
  }
  .para-2 p:first-child{
    top: 114px;
    left: 156px;
    font-size: 14px;
  }
  .para-2 a{
    top: 133px;
    left: 30px;
    font-weight: 450;
    font-size: 14px;
  }
  .para-3 a{
    /* top: 160px; */
    left: 125px;
  }
  .popup-header .signed2{
    left:171px;
 }
}

@media only screen and (max-width: 400px) {
    .popup-content {
    width: 325px;
    height: 227px;
    }
    .popup-header {
    width: 325px;
    height: 63px;
    left: 0px;
    }
    .popup-header .zingit-logo {
    width: 20px;
    height: 20px;
    left: 145px;
    top: 15px;
    }
    .popup-header .suite {
    font-weight: 450;
    font-size: 16px;
    line-height: 22px;
    top: 15px;
    left: 170px; 
    }
    .popup-header .signed {
    top: 35px;
    left: 105px;
    }
  .popup-body {
    width: 86%;
    height:65%;
    top: 70px;
    left: 20px;
    background:#fff;
  }
  .popup-body .para-1 p:first-child {
    top: 8px;
    font-size: 17px;
    line-height: 19px;
  }
  .popup-body .para-1 p:last-child {
    top: 30px;
    font-size: 14px;
    line-height: 15px;
  }
  .btn-continue {
    width: 280px;
    height: 40px;
    left: 1px;
    top: 66px;
    padding-top: 5px;
  }
  .para-2 p:first-child{
    top:110px;
    left:132px;
  }
  .para-2 a{
    top: 128px;
    left: 6px;
    font-weight: 400;
    font-size: 14px;
  }
  .para-3 a{
    top: 120px;
    left: 100px;
  }
  .popup-header .signed2{
    left: 145px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 300px) {
  ul.suite-boxes {
    margin-top: 20%;
    margin-left: 25%;
    width: 100%;
  }
  ul.suite-boxes li {
    width: 100px;
    height: 116px;
  }
  .popup-content {
    width: 250px;
    height: 210px;
    top: 60%;
  }
  .popup-header {
    width: 250px;
    height: 58px;
    left: 0px;
  }
  .popup-header .zingit-logo {
    width: 20px;
    height: 20px;
    left: 105px;
    top: 12px;
  }
  .popup-header .suite {
    font-weight: 450;
    font-size: 16px;
    line-height: 22px;
    top: 12px; 
    left: 131px;
  }
  .popup-header .signed {
    font-size: 14px;
    line-height: 16px;
    top: 35px;
    left: 70px;
  }
  .popup-body {
    width: 86%;
    height:65%;
    top: 58px;
    left: 15px;
  }
  .btn-continue {
    width: 220px;
    height: 35px;
    left: 1px;
    top: 67px;
    padding-top: 2px;
  }
  .para-2 p:first-child{
    top: 105px;
    left: 105px;
  }
  .para-2 a{
    top: 123px;
    padding-right: 178px;
    font-weight: 400;
    font-size: 14px;
  }
  .para-3 a{
    left: 70px;
  }
  .popup-header .signed2{
    left:107px;
 }
}

