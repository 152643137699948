body {
    background-color: #ffffff !important;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.row {
    margin-top: 0px;
}

a {
    text-decoration: none;
    letter-spacing: 0.5px;
    font-weight: 400;
    font-style: normal;
}

a:focus,
a:hover,
button:focus {
    outline: none;
    border: none;
    box-shadow: none !important;
    border-color: #f0f0fa;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
p,
ul {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
    margin-bottom: 0;
    letter-spacing: 0.5px;
}

label {
    letter-spacing: 0.5px;
}

h4 {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-style: normal;
}

p {
    font-size: 14px;
    margin-bottom: 0;
    letter-spacing: 0.5px;
    font-weight: 400;
    font-style: normal;
}

p.small {
    font-size: 12px;
    letter-spacing: 0.5px;

    font-weight: 400;
    font-style: normal;
}

img.zingitlogo {
    width: 35px;
}

img.export-icon {
    width: 30px;
    height: 30px;
    margin: 8px 16px 0 0;
}

.header-right {
    display: flex;
}

.accountLogo {
    width: 117px;
}

input:focus {
    outline: none !important;

    box-shadow: none !important;
}

.screens {
    float: right;
    width: calc(100%);
    transition: 0.5s;
}

.aside.mini + .screens {
    width: calc(100% - 58px);
    transition: 0.5s;
}

/*** Header ***/
.header {
    width: 100%;
    transition: 0.5s;
    background: linear-gradient(180deg, #E26131 0%, rgba(226, 97, 49, 0.8) 100%);
    border: 1px solid rgba(196, 193, 193, 0.3);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.header .navbar {
    display: flex;
    height: 66px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.short-container {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}

.short-container span {
    background: #f2994a;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.short-container img {
    margin-left: 10px;
    cursor: pointer;
}

.logout-drop {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 15%);
    border-radius: 4px !important;
    border: none !important;
    width: 230px;
    height: auto;
    position: absolute;
    top: 58px;
    padding: 20px;
    right: 20px;
    z-index: 9;
}

.logout-drop .user-info {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
}

.logout-drop .user-info span {
    background: #f2994a;
    border-radius: 20px;
    width: 54px;
    height: 35px;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-drop .user-box {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
}

.logout-drop .user-box h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #232323;
}

.logout-drop .user-box p {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #919191;
    margin-top: 5px;
    word-break: break-word;
}

.logout-drop .logout-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #444444;
    padding: 13px 0 0 0;
    justify-content: center;
}

.header-user {
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid rgba(255, 255, 255, 0.55);
    box-sizing: border-box;
    border-radius: 8px;
    height: 47px;
    padding: 8px;
}

.page-content {
    background-color: #ffffff;
    float: left;
    width: 100%;
    height: calc(100vh - 68px);
    padding: 0px;
    box-sizing: border-box;
}

.tooltip-export {
    margin-top: 1px !important;
}

/*** Auth Screens ***/
.auth-screen {
    width: 100%;
} 

/*** Login ***/
.logoLogin {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logoLogin img {
    width: 90px;
}

.logoLogin span {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0px;
    color: #595959;
    margin-top: 8px;
}

.logoLogin p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #595959;
    margin-top: 8px;
}

.loginFormRight {
    width: 100%;
    height: calc(100vh - 68px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;

}

.auth-form {
    margin-top: 20px;
    width: 100%;
}

.login-right-box {
    width: 462px;
    padding: 35px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border-radius: 4px;
}

.auth-form form label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #696F79;
    display: flex;
}

.auth-form input {
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0px 20px;
    text-decoration: 10px;
    background: transparent !important;
    height: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #333333 !important;
    width: 100%;
}

.auth-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #8692A6;
}

.auth-form input::-moz-placeholder { /* Firefox 19+ */
    color: #8692A6;
}

.auth-form input:-ms-input-placeholder { /* IE 10+ */
    color: #8692A6;
}

.auth-form input:-moz-placeholder { /* Firefox 18- */
    color: #8692A6;
}

.new-head-right {
    display: flex;
    align-items: center;
}
.new-head-right img{
    margin-right: 15px;
}
.new-head-right a{
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.75px;
    color: #FFFFFF;
}
.auth-form form .errorMsg {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #EB5757;
    float: right;
}

.auth-form button,
.auth-form a.button {
    width: 140px;
    min-width: 140px;
    height: 38px;
    border-radius: 5px;
    border: 0.5px solid #d9dadb;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 38px;
    letter-spacing: 0.1px;
    color: #000000;
}
.invalid-account-error{
    margin-bottom: 15px;
}
.invalid-account-error div{
    width:100%;
    background: #F9CDCD;
    opacity: 1;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EB5757;
    height:43px;
}

.pass-showHide{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0px 20px;
    width:100%;
    height: 60px;
}
.pass-showHide input{
    width:80%;
    height:auto;
    border:none;
    padding: 0;
}
.pass-showHide span{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color:#000000;
    cursor: pointer;
}
.pass-showHide input:focus{
    outline:none;
}

.defalut-loader-screen {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
}

.defalut-loader-screen img{
    width:40px;
}

.defalut-loader-screen h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #595959;
    margin-top: 25px;
}

.defalut-loader-screen p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #595959;
    margin-top: 15px;
}

button.button.login-btns.btn-primary {
    border: none;
    color: #ffffff;
    height: 60px;
    padding: 0px;
    background: linear-gradient(180deg, #E26131 0%, rgba(226, 97, 49, 0.79) 100%);
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-top:32px;
}

.auth-form button:hover {
    opacity: 1;
    border: 1px solid #3b5a6f;
    background: #3b5a6f;
}

button.button.update.btn.btn-primary {
    width: 134px;
    background: #2d9cdb;
    border-color: #2d9cdb;
}

/*** Header Support ***/
.all-screens-head {
    align-items: center;
    display: flex;
    margin-left: 0px;
    position: relative;
}

.all-screens-head span {
    padding-left: 10px;
    color: #fff;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 1px;
}

.MuiIconButton-root:hover {
    background-color: transparent;
}

.__react_component_tooltip.type-dark.place-right::after {
    border-right-color: #0079c1 !important;
}

.auth-form a.button.btn.btn-primary {
    background: #0079c1;
    border: 1px solid #0079c1;
}

.MuiPickersToolbar-toolbar {
    background-color: #0079c1 !important;
}

.MuiPickersDay-daySelected {
    background-color: #0079c1 !important;
}

.MuiButton-textPrimary {
    color: #0079c1 !important;
}

.MuiPickersClock-pin {
    background-color: #0079c1 !important;
}

.MuiPickersClockPointer-pointer {
    background-color: #0079c1 !important;
}

.MuiPickersClockPointer-noPoint {
    background-color: #0079c1 !important;
}

.MuiPickersClockPointer-thumb {
    border: 14px solid #0079c1 !important;
}

/*** Home Page ***/
ul.suite-boxes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 95%;
}

ul.suite-boxes.start-row {
    padding-top: 40px;
}

ul.suite-boxes li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 153px;
    transition: all 0.6s;
    background: #ffffff;
    border:1px solid #E0E0E0;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    margin:14.5px;
}
ul.suite-boxes li.blue-box{
    border-top:3px solid #0079C1;
}
ul.suite-boxes li.red-box{
    border-top:3px solid #EC525F;
}
ul.suite-boxes li.yellow-box{
    border-top:3px solid #F8AB3E;
}
ul.suite-boxes li a {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    cursor: pointer;
}

ul.suite-boxes li:hover {
    background: #f2682a;
    border-top:2px solid #f2682a;
    /*filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.02));*/
}
ul.suite-boxes li img.switch-active{
    display: none;
}
ul.suite-boxes li:hover img.switch-inactive {
    display: none;
}
ul.suite-boxes li:hover img.switch-active {
    display: block;
}
ul.suite-boxes li:hover a span{
    color:#ffffff;
}
ul.suite-boxes li:hover .z-form-new{
    display: none;
}
ul.suite-boxes li img {
    width: 28px;
    height: 28px;
}

ul.suite-boxes li a span {
    margin-top: 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #4b4b4b;
    letter-spacing: 0px;
    height:40px;
}

/*** Popup Modal ***/
.popup {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
}
.overlay {
    width: 100vw;
    height: 100vh;
    top: 67px;
    left: 0;
    position: fixed;
    background: rgba(7, 7, 7, 0.5);
}

.popup-content {
    position: absolute;
    top: 320px;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px 28px;
    width: 407px;
    height: 284px;
    border-radius: 10px 10px 10px 10px;

    font-family: Roboto;
    font-style: normal;
}

.popup-header {
    position: absolute;
    width: 407px;
    height: 79px;
    left: 0px;
    top: 0px;
    
    background: #FFFFFF;
    border-bottom: solid #e4dfdf 1px;
    border-radius: 10px 10px 0px 0px;
}
.popup-header .zingit-logo {
    position: absolute;
    width: 26.87px;
    height: 26px;
    left: 167px;
    top: 15px;
}

.popup-header .suite {
    position: absolute;
    width: 46px;
    height: 24px;
    left: 198px;
    top: 16px; 
    
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    
    color: #333333;
}

.popup-header .signed {
    position: absolute;
    top: 47px;
    left: 132px;
    text-align: center;

    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #000000;
}

.popup-header .signed2{
    left:171px;
 }

.popup-body {
    position: absolute;
    width: 86%;
    height:65%;
    top: 83px;
    left: 27px;
    background: #fff;
}

.popup-body .para-1 {
position: absolute;
}

.popup-body .para-1 p:first-child {
    position:absolute;
    top: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;

    letter-spacing: -0.01em;
    color: #4D4D4D;
}
 
.popup-body .para-1 p:last-child {
    position: absolute;
    top: 36px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    
    letter-spacing: 0.01em;
    color: #4D4D4D;
}

.select {
    top: 12px;
}

.btn-continue {
    position: absolute;
    width: 350px;
    height: 43px;
    left: 0px;
    top: 71px;

    color: #fff;
    background: linear-gradient(180deg, #E26131 0%, rgba(226, 97, 49, 0.79) 100%);
    border-radius: 4px;
}

.para-2 p:first-child{
    position: absolute;
    top:123px;
    left:165px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #333333;
 }

.para-2 a, .para-3 a {
    position: absolute;
    top: 151px;
    right: 0px;
    padding-left: 19px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: rgba(226, 97, 49, 1);
    width: 375px;
}

.para-3 a{
    top: 135px;
    width: 49px;
    left: 132px;  
}

.close-popup {
    position: absolute;
    top: 13px;
    right: 10px;
    padding: 5px 7px;
    color: #4D4D4D;
    transform: matrix(1, 0, 0, -1, 0, 0);
    opacity:0.80;
}

.close-popup:hover {
    opacity:1;
}

.custom-option {
    transition: background 30ms;
}
.custom-option:hover, .custom-option.custom-select__option--is-selected:hover {
    transition-delay: 30ms;
    background: #DEEBFF;
    color:#000000;
}
.custom-option.custom-select__option--is-focused {
    background: none;
}
.custom-option.custom-select__option--is-selected {
    background: #2684FF;
}

.beta-text {
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    position: absolute;
    right: -37px;
    top: 5px;
}

/*** Export popup ***/
.popup-content.export {
    height: 224px;
}
.popup-header.export {
    height: 49px;
    text-align: center;
    padding-top: 12px;
}

.popup-header .export-json {
    font-weight: 400;
    font-size: 20px;
}

.popup-body.export {
    width: 86%;
    height:55%;
    text-align: center;
}

.file-input {
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background: rgba(247, 246, 246, 0.344);
}

.btn-continue.export {
    top: 50px;
}

/*** Contact Support ***/
.contact-support-for-mobile {
    margin-top: 20px;
    display: none;

}
.contact-support-for-mobile a{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    letter-spacing: 0.75px;
    color: #0079C1;
}
.contact-support-broadcaste {
    width: 100%;
    height: calc(100vh - 68px);
    float: left;
}
.contact-support-broadcaste .support-info {
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 121, 193, 0.1);
    border-radius: 32px;
    flex-direction: column;
    padding: 50px;
}
.support-info h4 {
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0;
    color: #4B4B4B;
}
.support-info span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    color: #4B4B4B;
}
.support-info a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    color: #0079C1;
    padding: 0 4px;
    cursor: pointer;
    text-decoration: none !important;
}
.Open-ticket-button a{
    background: linear-gradient(180deg, #0079C3 0%, rgba(0, 121, 195, 0.79) 100%);
    border-radius: 6px;
    width:222px;
    height:60px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
}

